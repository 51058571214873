<template>
    <div class="card card-custom gutter-b">
        <!--begin::Body-->
        <div class="card-body pt-4">
            <!--begin::User-->
            <div class="d-flex align-items-center pt-4">
                <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                    <div class="symbol-label">
                        <img :src="imageSource(currentUser[0].profileimage)"
                            class="h-100 w-100 obcover br-42 align-self-center" alt="" />
                    </div>
                    <i class="symbol-badge bg-success" v-if="currentUser[0].onine == true"></i>
                    <i class="symbol-badge bg-success" v-else></i>
                </div>
                <div>
                    <a href="#" class="font-weight-bold font-size-h5 text-dark text-hover-primary">{{
                            currentUser[0].firstname
                    }}</a>
                    <div class="mt-2">
                        <router-link v-if="currentUser[0].isCoach && currentUser[0].isCoach == true"
                            :to="{ name: 'admincoaches' }"
                            class="btn btn-sm btn-ingomublue font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">
                            Coach
                        </router-link>
                        <a href="javascript:void(0)" v-if="currentUser[0].isAdmin && currentUser[0].isAdmin == true"
                            class="btn btn-sm btn-ingomugreen font-weight-bold py-2 px-3 px-xxl-5 my-1">Admin</a>
                    </div>
                </div>
            </div>
            <!--end::User-->
            <!--begin::Contact-->
            <div class="pt-8 pb-6">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="font-weight-bold mr-2">Email:</span>
                    <a href="#" class="text-dark text-hover-primary">{{ currentUser[0].email }}</a>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="font-weight-bold mr-2">User ID:</span>
                    <span class="text-dark text-hover-primary">{{ currentUser[0].id }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="font-weight-bold mr-2">Location:</span>
                    <span class="text-dark">{{ currentUser[0].city }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <span class="font-weight-bold mr-2">Joined:</span>
                    <span class="text-dark" v-if="currentUser[0].joined"> {{ shortFormatDate(currentUser[0].joined)
                    }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <span class="font-weight-bold mr-2">Goals:</span>
                    <span class="text-dark" v-if="currentUser[0].courses"> {{ String(currentUser[0].courses) }}</span>
                </div>
            </div>
            <!--end::Contact-->
            <div class="navi navi-bold navi-hover navi-active navi-link-rounded pt-8 pb-6">
                <div class="navi-item mb-2">
                    <a href="javascript:void(0)" class="navi-link py-4 active">
                        <span class="navi-icon mr-2">
                            <span class="svg-icon">
                                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Design/Layers.svg-->
                                <inline-svg src="/media/svg/icons/Home/Clock.svg" />
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                        <span class="navi-text font-size-lg">Free Trial</span>
                    </a>
                </div>
                <div class="navi-item">
                    <div class="navi-link d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Free Trial Status:</span>
                        <b-form-select v-model="freeUserState" :options="options" class="w-60" @change="updateFreeUserState"></b-form-select>
                        <!-- <a href="#" class="text-dark text-hover-primary">{{ freeUserState ? "Active" : "Expired" }}</a> -->
                    </div>
                    <div class="navi-link d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Expiration Date:</span>
                        <b-form-datepicker id="example-datepicker" v-model="freeUserExpireDate" class="w-60 mb-2" @input="updateFreeUserExpireDate">
                        </b-form-datepicker>
                        <!-- <a href="#" class="text-dark text-hover-primary" v-if="freeUserExpireDate != ''">{{
                                shortFormatDateMinusWeek(freeUserExpireDate)
                        }}</a> -->
                    </div>
                </div>
            </div>
            <div class="navi navi-bold navi-hover navi-active navi-link-rounded pt-8 pb-6">
                <div class="navi-item mb-2">
                    <a href="javascript:void(0)" class="navi-link py-4 active">
                        <span class="navi-icon mr-2">
                            <span class="svg-icon">
                                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Design/Layers.svg-->
                                <inline-svg src="/media/svg/icons/Home/Building.svg" />
                                <!--end::Svg Icon-->
                            </span>
                        </span>
                        <span class="navi-text font-size-lg">Corporate</span>
                    </a>
                </div>
                <div class="navi-item">
                    <div class="navi-link d-flex align-items-center justify-content-between mb-2">
                        <span class="font-weight-bold mr-2">Corporate Account:</span>
                        <a href="#" class="text-dark text-hover-primary">{{ corpUsers.length > 0 ? "True" : "False"
                        }}</a>
                    </div>
                    <div v-for="(item, i) in corpUsers" :key="i">
                        <div v-if="item.corpimage && item.corpimage != ''" class="text-center mb-4 mt-4">
                            <img :src="imageSource(item.corpimage)" class="h-100 w-50 obcover br-42 align-self-center"
                                alt="" />
                        </div>
                        <div class="navi-link d-flex align-items-center justify-content-between mb-2">
                            <span class="font-weight-bold mr-2">Corporate Name:</span>
                            <a href="#" class="text-dark text-hover-primary">{{ item.name }}</a>
                        </div>
                        <div class="navi-link d-flex align-items-center justify-content-between mb-2">
                            <span class="font-weight-bold mr-2">Expiration Date:</span>
                            <a href="#" class="text-dark text-hover-primary">{{ shortFormatDate(item.expiration_date)
                            }}</a>
                        </div>
                        <div class="navi-link d-flex align-items-center justify-content-between mb-2">
                            <span class="font-weight-bold mr-2">Status:</span>
                            <a href="#" class="text-dark text-hover-primary">{{ item.online == true ? "Active" :
                                    "Expired"
                            }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Body-->
    </div>
</template>

<script>
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'adminuserprofile',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    props: {
        currentUser: Array
    },
    data() {
        return {
            freeUserState: false,
            freeUserExpireDate: "",
            userid: "",
            options: [
                { value: true, text: 'Active' },
                { value: false, text: 'Expired' }
            ]
        }
    },
    computed: {
        ...mapGetters(["freeUsers", "corpUsers"])
    },
    methods: {
        updateFreeUserState(newValue) {
            if (this.userid != "") {
                this.$store.dispatch("updateFreeUserStatus", {
                    status: newValue,
                    uid: this.userid
                });
                Swal.fire("Updated!", "This will update on the backend in a few minutes. Refresh the Members List for the latest status.", "success");
            }
        },
        updateFreeUserExpireDate(newValue) {
            if (this.userid != "") {
                this.$store.dispatch("updateFreeUserExpirationDate", {
                    expireDate: newValue,
                    uid: this.userid
                });
                Swal.fire("Updated!", "This will update on the backend in a few minutes. Refresh the Members List for the latest status.", "success");
            }
        }
    },
    watch: {
        currentUser(newValue) {
            this.$store.dispatch("getFreeUserStatus", { userid: newValue[0].user_id })
            this.$store.dispatch("getCorpUserState", { userid: newValue[0].user_id })
            this.userid = newValue[0].user_id;
        },
        freeUsers(newValue) {
            this.freeUserState = newValue[0].online
            let myDate = new Date(newValue[0].timestamp.seconds * 1000);
            let newDate = new Date(myDate.setDate(myDate.getDate() + 7));
            this.freeUserExpireDate = newDate;
        },
    }
}
</script>
