<template>
    <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark fs-15">Members</span>
            </h3>
            <div class="card-toolbar">
                <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                    <li class="nav-item">
                        <export-excel class="btn btn-ingomugreen py-2 px-4 active mr-2 btn-sm" :fetch="getFullUsersList"
                            :fields="excel_fields" worksheet="User List" name="IngomuUserList.xls">
                            Excel
                        </export-excel>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link py-2 px-4 active font-weight-bolder mr-2" @click="reloadData()"
                            href="javascript:void(0)">
                            <span class="svg-icon svg-icon-white svg-icon-sm">
                                <inline-svg src="/media/svg/icons/General/Update.svg" />
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'freetrialsignup' }"
                            class="nav-link py-2 px-4 active font-weight-bolder">
                            Free Trial
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-2 pb-0" v-if="adminUsersList.length > 0">
            <div class="mt-5 mb-5">
                <b-form-group class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search by Email">
                        </b-form-input>
                        <b-input-group-append>
                            <b-button @click="searchByEmail">Submit</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <!--begin::Table-->
            <b-table id="latest-sessions-table" :items="adminUsersList" :fields="fields" show-empty
                class="table table-borderless table-vertical-center h-100 w-100 obcover br-42 align-self-center">
                <template #cell(image)="data" class="pl-0 py-4">
                    <div class="symbol symbol-50 symbol-light mr-1">
                        <span class="symbol-label">
                            <a href="javascript:void(0)" @click="emitUserDetails(data.item)"
                                class="symbol-label text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                <img :src="imageSource(data.item.profileimage)"
                                    class="h-100 w-100 obcover br-42 align-self-center" alt="" />
                                <i class="symbol-badge bg-success" v-if="data.item.onine == true"></i>
                                <i class="symbol-badge bg-success" v-else></i>
                            </a>
                        </span>
                    </div>
                </template>
                <template #cell(name)="data" class="pl-0 py-4">
                    <a href="javascript:void(0)" @click="emitUserDetails(data.item)"
                        class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ data.item.firstname }}
                    </a>
                    <a href="javascript:void(0)" class="text-dark font-weight-bold d-block"
                        @click="emitUserDetails(data.item)">{{ data.item.email }}</a>
                </template>
                <template #cell(date)="data" class="pl-0 py-4">
                    <a href="javascript:void(0)" @click="emitUserDetails(data.item)">
                        {{ shortFormatDate(data.item.joined) }}
                    </a>
                </template>
            </b-table>
            <div class="row justify-content-center mt-7 mb-7">
                <div class="col-xl-12">
                    <div class="next-prev-btn d-flex align-items-center justify-content-between">
                        <div class="prev-btn">
                            <a class="btn-link btn-link--prev" href="javascript:void(0)" v-if="prev_users_all"
                                @click="getPrevUserList()"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;prev</a>
                        </div>
                        <div class="prev-btn">
                            <a class="btn-link btn-link--next" href="javascript:void(0)" v-if="next_users_all"
                                @click="getNextUserList()">next&nbsp;&nbsp;<i class="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: 'adminusers',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    data() {
        return {
            fields: [
                { key: "image", label: "" },
                { key: "name", label: "First Name/Email" },
                { key: "date", label: "Date Joined" },
            ],
            filter: null,
            excel_fields: {
                'First Name': 'firstname',
                'Email': 'email',
                'City': 'city',
                'Joined': {
                    field: 'joined',
                    callback: (value) => {
                        let myDate = new Date(value.seconds * 1000);
                        return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()}`;
                    }
                },
                'User ID': 'user_id'
            }
        }
    },
    mounted() {
        this.$store.dispatch("resetAdminUsersList");
        this.$store.dispatch("getNextAdminUsersList", { firstTime: true });
    },
    computed: {
        ...mapGetters(["adminUsersList", "next_users_all", "prev_users_all"])
    },
    methods: {
        getFullUsersList() {
            return this.$store.dispatch("getFullAdminUsersList")
        },
        getNextUserList() {
            this.$store.dispatch("getNextAdminUsersList", { firstTime: false });
        },
        getPrevUserList() {
            this.$store.dispatch("getPrevAdminUsersList");
        },
        reloadData() {
            this.filter = "";
            this.$store.dispatch("resetAdminUsersList");
            this.$store.dispatch("getNextAdminUsersList", { firstTime: true });
            let userDetails = {
                age: "",
                city: "",
                courses: [],
                email: "",
                firstname: "",
                id: "",
                isCoach: false,
                joined: "",
                last_active: "",
                online: true,
                profileimage: "https://ingomucdn.s3.amazonaws.com/image/png/user-blank.png",
                token: [],
                user_id: ""
            }
            this.$emit('click', userDetails);
        },
        searchByEmail() {
            if (this.filter != "") {
                this.$store.dispatch("getAdminUsersByEmail", { email: this.filter })
            }
        },
        emitUserDetails(event) {
            this.$emit('click', event);
        }
    }
}
</script>
