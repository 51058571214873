<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <UserNav activePage="adminusers" activeTab="2"></UserNav>
                <!--begin::Content-->
                <div class="flex-row-fluid ml-lg-8">
                    <!--begin::Content-->
                    <div class="flex-row-fluid ">
                        <div class="row">
                            <div class="col-xl-7">
                                <AdminWidget6 @click="onUserClicked"></AdminWidget6>
                            </div>
                            <div class="col-xl-5">
                                <AdminWidget7 :currentUser=currentUser></AdminWidget7>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminWidget6 from "@/view/content/widgets/admin/Widget6.vue";
import AdminWidget7 from "@/view/content/widgets/admin/Widget7.vue"
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
    name: 'adminusers',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    components: {
        AdminWidget6,
        AdminWidget7,
        UserNav
    },
    data() {
        return {
            currentUser: [
                {
                    age: "",
                    city: "",
                    courses: [],
                    email: "",
                    firstname: "",
                    id: "",
                    isCoach: false,
                    joined: "",
                    last_active: "",
                    online: true,
                    profileimage: "https://ingomucdn.s3.amazonaws.com/image/png/user-blank.png",
                    token: [],
                    user_id: ""
                }
            ]
        }
    },
    methods: {
        onUserClicked(value) {
            this.currentUser = [value]
        }
    }
}
</script>
